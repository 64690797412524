import React, { Fragment } from 'react'
import './TabellaCedolino.css'

const TabellaCedolino = ({ cedolino }) => {
  return (
    <table className="tabella-cedolino w-100 center">
      <thead>
        <tr>
          <th className="tl">Descrizione</th>
          <th>Ore</th>
          <th>Giorni</th>
          <th>Imp. unitario</th>
          <th>Totale</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="tl">{cedolino.R01_C1}</td>
          <td>{cedolino.R01_C2}</td>
          <td>{cedolino.R01_C3}</td>
          <td>{cedolino.R01_C4}</td>
          <td>{cedolino.R01_C5}</td>
        </tr>
        <tr>
          <td className="tl">{cedolino.R02_C1}</td>
          <td>{cedolino.R02_C2}</td>
          <td>{cedolino.R02_C3}</td>
          <td>{cedolino.R02_C4}</td>
          <td>{cedolino.R02_C5}</td>
        </tr>
        <tr>
          <td className="tl">{cedolino.R03_C1}</td>
          <td>{cedolino.R03_C2}</td>
          <td>{cedolino.R03_C3}</td>
          <td>{cedolino.R03_C4}</td>
          <td>{cedolino.R03_C5}</td>
        </tr>
        {cedolino.mese !== 13 && (
          <Fragment>
            <tr>
              <td className="tl">Straordinarie al 10%</td>
              <td>{cedolino.R04B_C2}</td>
              <td />
              <td>{cedolino.R04B_C4}</td>
              <td>{cedolino.R04B_C5}</td>
            </tr>
            <tr>
              <td className="tl">Straordinarie al 25%</td>
              <td>{cedolino.R04_C2}</td>
              <td />
              <td>{cedolino.R04_C4}</td>
              <td>{cedolino.R04_C5}</td>
            </tr>
            <tr>
              <td className="tl">Straordinarie al 40%</td>
              <td>{cedolino.R05_C2}</td>
              <td />
              <td>{cedolino.R05_C4}</td>
              <td>{cedolino.R05_C5}</td>
            </tr>
            <tr>
              <td className="tl">Straordinarie notturne</td>
              <td>{cedolino.R06_C2}</td>
              <td />
              <td>{cedolino.R06_C4}</td>
              <td>{cedolino.R06_C5}</td>
            </tr>
            <tr>
              <td className="tl">Straordinarie festive</td>
              <td>{cedolino.R07_C2}</td>
              <td />
              <td>{cedolino.R07_C4}</td>
              <td>{cedolino.R07_C5}</td>
            </tr>
          </Fragment>
        )}
        <tr>
          <td className="tl">Vitto e alloggio in denaro</td>
          <td />
          <td>{cedolino.R08_C3}</td>
          <td>{cedolino.R08_C4}</td>
          <td>{cedolino.R08_C5}</td>
        </tr>
        <tr>
          <td className="tl">Vitto e alloggio in natura</td>
          <td />
          <td>{cedolino.R09_C3}</td>
          <td>{cedolino.R09_C4}</td>
          <td>{cedolino.R09_C5}</td>
        </tr>
        {cedolino.mese !== 13 && (
          <Fragment>
            <tr>
              <td className="tl">Festività</td>
              <td />
              <td>{cedolino.R11_C3}</td>
              <td />
              <td>{cedolino.R11_C5}</td>
            </tr>
            <tr>
              <td className="tl">Malattia effettuata</td>
              <td />
              <td>{cedolino.R13_C3}</td>
              <td />
              <td />
            </tr>
            <tr>
              <td className="tl">Malattia retribuita</td>
              <td />
              <td>{cedolino.R14_C3}</td>
              <td />
              <td>{cedolino.R14_C5}</td>
            </tr>
            <tr>
              <td className="tl">{cedolino.R16B_C1}</td>
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td className="tl">{cedolino.R17_C1}</td>
              <td />
              <td>{cedolino.R16_C3}</td>
              <td />
              <td>{cedolino.R16_C5}</td>
            </tr>
            <tr>
              <td className="tl">{cedolino.R17B_C1}</td>
              <td />
              <td />
              <td />
              <td />
            </tr>
          </Fragment>
        )}
        {/* {!!cedolino.R18_C5 && ( */}
          <tr>
            <td className="tl">{cedolino.mese !== 13 ? cedolino.NOTEEXTRA : 'Importi extra'}</td>
            <td />
            <td />
            <td />
            <td>{cedolino.R18_C5}</td>
          </tr>
        {/* )} */}
        {cedolino.mese !== 13 ? (
          <tr>
            <td className="tl">Permessi non retribuiti</td>
            <td>{cedolino.R20_C2}</td>
            <td>{cedolino.R20_C3}</td>
            <td />
            <td>{cedolino.R20_C5}</td>
          </tr>
        ) : (
          <tr>
            <td style={{ height: 300 }} />
            <td />
            <td />
            <td />
            <td />
          </tr>
        )}
        <tr className="lordo">
          <td className="tl">Importo lordo</td>
          <td />
          <td />
          <td />
          <td>€&nbsp;{cedolino.R23_C5}</td>
        </tr>
        {cedolino.mese !== 13 && (
          <Fragment>
            <tr>
              <td className="tl">Contributi INPS</td>
              <td>{cedolino.R25_C2}</td>
              <td />
              <td>{cedolino.R25_C4}</td>
              <td>{cedolino.R25_C5}</td>
            </tr>
            <tr>
              <td className="tl">Contr. assistenza contrattuale</td>
              <td>{cedolino.R26_C2}</td>
              <td />
              <td>{cedolino.R26_C4}</td>
              <td>{cedolino.R26_C5}</td>
            </tr>
          </Fragment>
        )}
        {cedolino.mese === 13 && (
          <tr>
            <td className="tl">Mesi tredicesima</td>
            <td />
            <td />
            <td />
            <td>{cedolino.R25_C5}</td>
          </tr>
        )}
        <tr className="totale fw6">
          <td className="tl">Importo netto</td>
          <td />
          <td />
          <td />
          <td>€&nbsp;{cedolino.R27_C5}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default TabellaCedolino
