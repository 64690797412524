import { gql } from 'apollo-boost'

export default gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      me {
        nome
        anni
      }
    }
  }
`
