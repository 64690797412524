import React from 'react'
import moment from 'moment'

import './IntestazioneCedolino.css'

const IntestazioneCedolino = ({ cedolino }) => {
  return (
    <div className="intestazione-cedolino">
      <table className="w-100">
        <tbody>
          <tr>
            <td className="w-50">
              <p className="mt0 mb2">
                <b>Lavoratore:</b>
              </p>
              {cedolino.colfNome}
              <br />
              {cedolino.colfIndirizzo1}
              <br />
              {cedolino.colfIndirizzo2}
              <br />
              {cedolino.colfCodiceFiscale && `C.F. ${cedolino.colfCodiceFiscale}`}
              <br />
              <br />
              Data assunzione: {moment(cedolino.colfDataAssunzione).format('DD/MM/YYYY')}
              <br />
              Categoria: {cedolino.colfCategoria}
              <br />
            </td>

            <td className="w-50 ba br4 pa4">
              Datore di lavoro:<br />
              <b>{cedolino.datoreNome}</b>
              <br />
              {cedolino.datoreIndirizzo1}
              <br />
              {cedolino.datoreIndirizzo2}
              <br />
              C.F. {cedolino.datoreCodiceFiscale}
            </td>
          </tr>
        </tbody>
      </table>

      <h2 className="periodo w-100 pa2 center tc ba">{cedolino.periodo}</h2>
    </div>
  )
}

export default IntestazioneCedolino
