import { gql } from 'apollo-boost'

export default gql`
  query getUtente {
    me {
      nome
      lastLogin
      lastIp
      ente {
        nome
        riga1
      }
      anni
    }
  }
`
