import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Link } from 'react-router-dom'

import getUtente from '../../graphql/getUtente'
import getCedolini from '../../graphql/getCedolini'
import Layout from '../Layout/Layout'

import './Cedolini.css'

const nomeMese = mese => {
  switch (mese) {
    case 1:
      return 'Gennaio'
    case 2:
      return 'Febbraio'
    case 3:
      return 'Marzo'
    case 4:
      return 'Aprile'
    case 5:
      return 'Maggio'
    case 6:
      return 'Giugno'
    case 7:
      return 'Luglio'
    case 8:
      return 'Agosto'
    case 9:
      return 'Settembre'
    case 10:
      return 'Ottobre'
    case 11:
      return 'Novembre'
    case 12:
      return 'Dicembre'
    case 13:
      return 'Tredicesima'
    default:
      return ''
  }
}

const RigaCedolino = ({ cedolino }) => (
  <tr>
    <td>{cedolino.colfNome}</td>
    <td>{nomeMese(cedolino.mese)}</td>
    <td className="tr">€&nbsp;{cedolino.R27_C5}</td>
    <td>
      <Link to={`/cedolini/${cedolino.id}`} className="vedi-cedolino db tc ml3 f6 link bn br2 ph3 pv2 white pointer">
        Vedi cedolino
      </Link>
    </td>
  </tr>
)

class Cedolini extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anno: '',
    }
  }

  handleChangeAnno = event => {
    this.setState({
      anno: event.target.value,
    })
  }

  render() {
    return (
      <Layout>
        <div className="cedolini">
          <Query query={getUtente}>
            {({ loading, error, data }) => {
              if (loading || error) return <div />

              return (
                <div>
                  <h2>Cedolini {data.me.nome}</h2>
                  <div className="pv2 ph3 bg-black-10 mb3">
                    Seleziona l'anno:&nbsp;&nbsp;
                    <select value={this.state.anno} onChange={this.handleChangeAnno}>
                      <option value="">-- Seleziona --</option>
                      {data.me.anni.map(anno => (
                        <option key={anno} value={anno}>
                          {anno}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )
            }}
          </Query>

          <Query query={getCedolini} variables={{ anno: this.state.anno }} skip={!this.state.anno}>
            {({ loading, error, data }) => {
              if (loading) return <div>Caricamento...</div>
              if (error) return <div>E' avvenuto un errore durante il caricamento dei dati.</div>

              if (data && data.me.cedolini.length === 0) return <div>Nessun cedolino disponibile per l'anno selezionato.</div>

              return (
                <table className="w-100">
                  <thead>
                    <tr>
                      <th className="tl">Colf</th>
                      <th className="tl">Mese</th>
                      <th className="tr">Importo</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>{data && data.me.cedolini.map(cedolino => <RigaCedolino key={cedolino.id} cedolino={cedolino} />)}</tbody>
                </table>
              )
            }}
          </Query>
          <p className="mt4">Per terminare la sessione, clicca su "Esci"</p>
        </div>
      </Layout>
    )
  }
}

export default Cedolini
