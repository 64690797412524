import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import loginMutation from '../../graphql/login'

import Layout from '../Layout/Layout'

import './Login.css'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      errore: '',
    }
  }

  handleChange = field => event => {
    this.setState({
      [field]: event.target.value,
    })
  }

  handleLogin = event => {
    event.preventDefault()
    this.props
      .login({
        variables: {
          username: this.state.username,
          password: this.state.password,
        },
      })
      .then(({ data }) => {
        localStorage.setItem('token-apicolf', data.login.token)
        this.props.history.push('/home')
      })
      .catch(error => {
        this.setState({
          errore: error,
        })
      })
  }

  render() {
    return (
      <Layout>
        <div className="login mt4">
          <form className="measure center" onSubmit={this.handleLogin}>
            <fieldset className="ba b--transparent ph0 mh0">
              <legend className="f4 fw6 ph0 mh0">Accedi all'area riservata</legend>
              <div className="mt3">
                <label className="db fw6 lh-copy f6 black-70" htmlFor="username">
                  Nome utente
                </label>
                <input
                  id="username"
                  className="input-reset pa2 ba br2 b--black-30 w-100"
                  type="text"
                  value={this.state.username}
                  onChange={this.handleChange('username')}
                />
              </div>
              <div className="mv3">
                <label className="db fw6 lh-copy f6 black-70" htmlFor="password">
                  Password
                </label>
                <input
                  id="password"
                  className="input-reset pa2 ba br2 b--black-30 w-100"
                  type="password"
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                />
              </div>
            </fieldset>
            <div>
              <button type="submit" className="f6 bn br2 ph3 pv2 white pointer">
                Accedi
              </button>
              <div className="mt4">
                Inserire la password esattamente come fornita<br />
                (maiuscole / minuscole)
              </div>
              {this.state.errore && <div className="error mt4 pa3 br2">Username o password errati</div>}
            </div>
          </form>
        </div>
      </Layout>
    )
  }
}

export default graphql(loginMutation, { name: 'login' })(Login)
