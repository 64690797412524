import { gql } from 'apollo-boost'

export default gql`
  query getCedolini($anno: Int!) {
    me {
      cedolini(anno: $anno) {
        id
        mese
        colfNome
        R27_C5
      }
    }
  }
`
