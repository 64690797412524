import React from 'react'
import { NavLink } from 'react-router-dom'
import { withApollo, Query } from 'react-apollo'

import getUtente from '../../graphql/getUtente'

import logo from '../../images/apicolf.png'
import './Header.css'

const Header = ({ loggato, ente, client }) => {
  const logout = () => {
    localStorage.removeItem('token-apicolf')
    client.resetStore()
  }

  const token = localStorage.getItem('token-apicolf')

  return (
    <header className={`header tc mb4`}>
      <img src={logo} alt="API Colf" className="mt1 mb3" />

      <Query query={getUtente} fetchPolicy={!token ? 'cache-only' : undefined}>
        {({ loading, error, data }) => {
          if (loading || error) return null
          return (
            <div className="loggato">
              {data && data.me && (
                <div className="ente">
                  {/* <h3 className="f5 gray fw4 ttu tracked">{data.me.ente.nome}</h3> */}
                  <p className="f7 gray fw2">{data.me.ente.riga1}</p>
                </div>
              )}
              <div className="menu mt3">
                {!!data ? (
                  <div className="nav">
                    <NavLink to="/home" className="f5 fw5 hover-white no-underline white-80 dib pv3 ph4" activeClassName="bg-white-20 white">
                      Home
                    </NavLink>
                    <NavLink to="/cedolini" className="f5 fw5 hover-white no-underline white-80 dib pv3 ph4" activeClassName="bg-white-20 white">
                      Cedolini
                    </NavLink>
                    <NavLink to="/" onClick={logout} className="f5 fw4 hover-white no-underline white-80 dib pv3 ph4">
                      Esci
                    </NavLink>
                  </div>
                ) : (
                  <div style={{ height: 2 }} />
                )}
              </div>
            </div>
          )
        }}
      </Query>
    </header>
  )
}

export default withApollo(Header)
