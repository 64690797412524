import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'

import Login from '../Login/Login'
import Home from '../Home/Home'
import Cedolini from '../Cedolini/Cedolini'
import Cedolino from '../Cedolino/Cedolino'

const client = new ApolloClient({
  uri: 'https://api.clienti.apiservizi.bg.it/graphql',
  // uri: 'https://api.clienti.apicolf.it/proxy',
  request: async operation => {
    const token = localStorage.getItem('token-apicolf')
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    })
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.log(graphQLErrors)
    }
    if (networkError) {
      console.log('Net error', networkError)
    }
  },
})

class App extends Component {
  render() {
    return (
      <div className="app sans-serif">
        <ApolloProvider client={client}>
          <Router>
            <div className="w-60-l w-90-ns w-90 pv2 center">
              <Route exact path="/" component={Login} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/cedolini" component={Cedolini} />
              <Route path="/cedolini/:id" component={Cedolino} />
            </div>
          </Router>
        </ApolloProvider>
      </div>
    )
  }
}

export default App
