import React from 'react'
import { Query } from 'react-apollo'

import getCedolino from '../../graphql/getCedolino'

import Layout from '../Layout/Layout'
import IntestazioneCedolino from './IntestazioneCedolino'
import TabellaCedolino from './TabellaCedolino'

const Cedolino = props => {
  return (
    <Layout>
      <div className="cedolino">
        <Query query={getCedolino} variables={{ id: props.match.params.id }}>
          {({ loading, error, data }) => {
            if (loading) return <div>Caricamento...</div>
            if (error) return <div>E' avvenuto un errore durante il caricamento dei dati.</div>

            return (
              <div>
                <IntestazioneCedolino cedolino={data.cedolino} />
                <TabellaCedolino cedolino={data.cedolino} />
              <p className="mt3 f7">Il prospetto riportato non sostituisce l'originale.</p>

              </div>
            )
          }}
        </Query>
      </div>
    </Layout>
  )
}

export default Cedolino
