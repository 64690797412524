import { gql } from 'apollo-boost'

export default gql`
  query getCedolino($id: ID!) {
    cedolino(id: $id) {
      id
      codiceUtente
      codiceEnte
      mese
      anno
      tipoRetribuzione
      colfNome
      colfIndirizzo1
      colfIndirizzo2
      colfCodiceFiscale
      colfDataAssunzione
      colfCategoria
      datoreNome
      datoreIndirizzo1
      datoreIndirizzo2
      datoreCodiceFiscale
      periodo
      R01_C1
      R01_C2
      R01_C3
      R01_C4
      R01_C5
      R02_C1
      R02_C2
      R02_C3
      R02_C4
      R02_C5
      R03_C1
      R03_C2
      R03_C3
      R03_C4
      R03_C5
      R04_C2
      R04_C4
      R04_C5
      R05_C2
      R05_C4
      R05_C5
      R06_C2
      R06_C4
      R06_C5
      R07_C2
      R07_C4
      R07_C5
      R08_C3
      R08_C4
      R08_C5
      R09_C3
      R09_C4
      R09_C5
      R11_C3
      R11_C5
      R13_C3
      R14_C3
      R14_C5
      R16_C3
      R16_C5
      R18_C5
      R20_C2
      R20_C3
      R20_C5
      R23_C5
      R25_C2
      R25_C4
      R25_C5
      R27_C5
      TIPO_COP1
      TIPO_COP2
      NOTE_CED
      AVVISI_1
      AVVISI_2
      DATA_STAMP
      CODENTE
      X_RETE
      NOTEEXTRA
      R04B_C2
      R04B_C4
      R04B_C5
      R16B_C1
      DA_GRUPDIP
      R17_C1
      R17B_C1
      R26_C2
      R26_C4
      R26_C5
    }
  }
`
