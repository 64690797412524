import React from 'react'
import { Query } from 'react-apollo'
import moment from 'moment'

import getUtente from '../../graphql/getUtente'
import Layout from '../Layout/Layout'
import imgHome from '../../images/home_img.jpg'

const Home = () => {
  return (
    <Layout>
      <div className="home">
        <Query query={getUtente}>
          {({ loading, error, data }) => {
            if (loading) return <div>Caricamento...</div>
            if (error)
              return (
                <div>
                  E' avvenuto un errore durante il caricamento dei dati.
                </div>
              )
            return (
              <div>
                <h2>Benvenuto, {data.me.nome}!</h2>
                {/*<p style={{color: "red"}}>Api Servizi informa che l’ufficio sarà chiuso al pubblico dal 23.12.2022 al 03.01.2023 e aprirà il 4 e il 5 gennaio solo per il ritiro delle buste paga e dei prospetti dei contributi INPS.<br></br>

                <b>La invitiamo a comunicarci eventuali variazioni relative al mese di dicembre entro il 15/12/2022.</b> 
                Le variazioni comunicate oltre tale data saranno conguagliate nel listino paga di gennaio 2023.<br></br>
                <br></br>
                <b>Le Tredicesime saranno pronte dal 19/12/2022.</b><br></br>
                <br></br>
            Buone Feste!</p>*/}

                <img src={imgHome} alt="paesaggio home" />
                <p>
                  Ultimo accesso:{' '}
                  {moment(data.me.lastLogin).format('DD/MM/YYYY')}{' '}
                  dall'indirizzo IP {data.me.lastIp}
                </p>
              </div>
            )
          }}
        </Query>
        <p className="mt2">Per terminare la sessione, clicca su "Esci"</p>
      </div>
    </Layout>
  )
}

export default Home
